import {animate, state, style, transition, trigger} from "@angular/animations";

export const flyInOutAnimation = trigger("flyInOut", [
	state("in", style({transform: "translateX(0)", opacity: 1})),
	transition("void => *", [
		style({transform: "translateX(50%)", opacity: 0}),
		animate("400ms ease")
	]),
	transition("* => void", [
		animate("400ms ease", style({transform: "translateX(50%)", opacity: 0}))
	])
]);

export const flyUpAnimation = trigger("flyUp", [
	state("in", style({transform: "translate(0, 0)", opacity: 1})),
	transition("void => *", [
		style({transform: "translate(0, 50%)", opacity: 0}),
		animate("400ms ease")
	]),
	transition("* => void", [
		animate("400ms ease", style({transform: "translate(0, 50%)", opacity: 0}))
	])
]);

export const fadeInOutAnimation = trigger("fadeInOut", [
	state("in", style({opacity: 1})),
	transition("void => *", [
		style({opacity: 0}),
		animate("300ms ease-in-out")
	]),
	transition("* => void", [
		animate("300ms ease-in-out", style({opacity: 0}))
	])
]);
